/*
    Video
 */
.video-content-block {
    position: relative;

    padding-top: rem($vertical-spacing);

    background-color: $color-primary;

    // State
    &.plum {
        background-color: $color-secondary;
    }

    &.coral {
        background-color: $color-supplementary-coral;
    }

    &.navy {
        background-color: $color-supplementary-navy;
    }

    @media (--from-small-screen-w) {
        padding-top: rem($vertical-spacing-large);
        margin-bottom: rem($vertical-spacing-large);
    }

    @media (--from-medium-screen-w) {
        padding-top: rem($vertical-spacing-x-large);
    }


    // Elements
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: rem(40);

        background-color: $color-light;

        content: '';

        @media (--from-medium-screen-w) {
            height: rem(60);
        }
    }

    .meta {
        max-width: rem($constrain-width-small);
        padding: 0 rem($horizontal-spacing-medium) rem(10);
        margin-right: auto;
        margin-left: auto;

        text-align: center;


        // Elements
        &-title {
            margin-bottom: rem(8);

            color: $color-light;
        }

        &-credit {
            color: $color-lime;
            font-size: rem(12);
        }
    }

    &-inner {
        @media (--from-small-screen-w) {
            width: calc(100% - rem($horizontal-spacing-x-large));
        }


        // Elements
        .video {
            width: 100%;
            height: 100%;

            background: $color-dark;
            border: none;
        }
    }
}
