/*
    Enquiry Section
*/
.enquiry-section {
    position: relative;


    // Elements
    &-inner {
        @media(--from-small-screen-w) {
            display: flex;
            flex-wrap: wrap;

            text-align: center;
        }

        @media(--from-normal-screen-w) {
            flex-wrap: nowrap;

            text-align: left;
        }
    }

    &-background {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        min-height: rem($section-image-height);

        background-size: cover;

        @media(--from-normal-screen-w) {
            left: 50%;

            width: 50%;
            height: 100%;
            min-height: rem($section-height-large);
        }
    }

    &-media {
        max-height: rem($section-height-large);
        min-height: rem($section-image-height);

        background-image: url(#{$folder-image}/enquiry.jpg);

        @media(--from-small-screen-w) {
            flex-basis: 100%;
        }

        @media(--from-normal-screen-w) {
            flex-basis: 50%;
            order: 2;
            min-height: rem($section-height-large);

            background-color: transparent;
            background-image: none;
        }

    }

    &-contact {
        padding-top: rem($vertical-spacing);
        padding-bottom: rem($vertical-spacing-x-large);


        @media(--from-small-screen-w) {
            display: flex;
            align-items: center;
            flex-basis: 100%;
            justify-content: center;

            padding-top: rem($vertical-spacing-large);
            padding-bottom: rem($vertical-spacing-large);
        }

        // RFAW-3748 - Remove background image for now.
        // @media(--from-normal-screen-w) {
        //     flex-basis: 50%;
        //     justify-content: flex-start;
        // }
        // &-inner {
        //     @media(--from-normal-screen-w) {
        //         // flex-basis: 100%;
        //         // padding-right: rem(20);
        //     }
        // }

        // Elements
        &-description {
            margin-bottom: rem(35);
        }

        &-form {
            @media(--from-small-screen-w) {
                flex-basis: 100%
            }
        }
    }
}
