/**
    Tile
*/
.tile {
    @media (--from-small-screen-w) {
        display: flex;
        flex-direction: column;
    }


    // States
    &:hover {
        @media (--from-normal-screen-w) {
            .tile-thumbnail {
                opacity: 0.5;
            }

            .text-link {
                opacity: 1;
            }
        }
    }


    // Elements
    &-inner {
        position: relative;

        padding-bottom: rem(40);

        @media(--from-small-screen-w) {
            flex-grow: 1;
            padding-top: rem(26); // Allow for the fixed .tile-subtitle
        }
    }

    &-subtitle {
        color: $color-primary;

        @media(--from-small-screen-w) {
            position: absolute;
            top: 0;
        }
    }

    &-thumbnail {
        max-height: rem(240);
        margin-bottom: rem($vertical-spacing-medium);
        overflow: hidden;

        background-color: $color-dark;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (--from-small-screen-w) {
            transition: opacity 0.2s ease-in-out;
        }

        &::before {
            display: block;
            padding-bottom: calc(100% * 3 / 4);

            content: '';
        }
    }

    &-title {
        margin-bottom: rem(10);
    }

    &-description {
        font-size: rem(16);
    }

    .text-link {
        position: absolute;
        bottom: rem(15);

        @media (--from-normal-screen-w) {
            opacity: 0;

            transition: opacity 0.2s ease-in-out;
        }
    }
}
