.search {
    &-icon {
        color: $color-white;
        height: rem(50);
        margin-left: rem(8);

        @media (--from-navigation-breakpoint) {
            height: rem(100);
            margin-right: rem(8);
        }
        &:hover {
            color: color($color-light alpha(60%));
        }
    }
}