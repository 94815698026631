/*
    Text Link
 */
button.text-link {
    background-color: transparent;
    border: none;

    appearance: none;
}

.text-link {
    display: inline-flex;
    align-items: center;
    height: rem(30);

    color: $color-primary-shade-1;
    font-family: $font-primary;
    font-size: rem(14);
    letter-spacing: 0.1em;
    text-transform: uppercase;


    // Types
    &.large {
        @media(--from-small-screen-w) {
            font-size: rem(18);
        }

        .icon {
            margin-right: rem(-5);

            @media(--from-small-screen-w) {
                width: rem(36);
                height: rem(28);
            }
        }
    }


    // Elements
    .icon {
        position: relative;
        top: rem(-1); // Tweak vertical alignment

        margin-left: rem(5);
    }
}
