/*
    Sub Navigation
 */
$--dropdown-width: 280;

.sub-navigation {
    @media (--to-navigation-breakpoint) {
        display: none;
        flex-basis: 100%;
        padding-bottom: rem(20);
        padding-left: rem($horizontal-spacing-large);

        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $overlay-dark-weak 100%);
    }

    @media (--from-navigation-breakpoint) {
        position: absolute;
        top: 100%;
        left: calc(100% - 20px);

        width: rem($--dropdown-width);
        height: 0;
        overflow: hidden;

        color: $color-light;

        opacity: 0;

        transform: translate(-50%, rem(20));
        transition:
            height 0 ease-in-out 0.15s,
            overflow 0 ease-in-out 0.15s,
            transform 0.15s ease-in-out,
            opacity 0.15s ease-in-out;
    }


    // Types
    &.two-column {
        @media (--from-navigation-breakpoint) {
            width: calc(rem($--dropdown-width) * 2);
        }


        // Elements
        .sub-navigation-inner {
            @media (--from-navigation-breakpoint) {
                position: relative;

                display: flex;
                flex-wrap: wrap;
            }

            &::after {
                @media (--from-navigation-breakpoint) {
                    position: absolute;
                    top: 0;
                    left: 50%;

                    display: block;
                    width: rem(1);
                    height: 100%;

                    pointer-events: none;
                    background-color: color($color-dark alpha(30%));

                    content: '';

                }
            }
        }

        .sub-navigation-item {
            @media (--from-navigation-breakpoint) {
                flex-basis: 50%;
                max-width: 50%; // IE: flex-wrap requires max-width set on children
            }
        }
    }


    // States
    &.is-active {
        color: $color-light;
    }


    // Elements
    &::before {
        // This is just a blank "spacer" block
        @media (--from-navigation-breakpoint) {
            display: block;
            width: 100%;
            height: rem(20);

            content: '';
        }
    }

    &-inner {
        @media (--from-navigation-breakpoint) {
            background-color: color($color-dark alpha(70%));
        }
    }

    &-item {
        display: flex;
        align-items: center;
        height: rem(50);

        color: $color-light;
        font-size: rem(14);
        letter-spacing: 0.05em;
        text-decoration: none;
        text-transform: uppercase;

        transition:
            color ease-in-out 0.15s,
            background-color ease-in-out 0.15s;

        @media (--from-navigation-breakpoint) {
            padding-left: rem(20);

            background-color: transparent;
        }


        // States
        &:hover,
        &.is-active {
            color: color($color-light alpha(80%));

            @media (--from-navigation-breakpoint) {
                color: $color-light;

                background-color: $color-primary-shade-2;
            }
        }
    }
}
