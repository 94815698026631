/*
    Pagebreak-quote
*/
.pagebreak-quote {
    position: relative;

    background-color: $color-primary;


    // State
    &.plum {
        background-color: $color-secondary;
    }

    &.coral {
        background-color: $color-supplementary-coral;
    }

    &.navy {
        background-color: $color-supplementary-navy;
    }

    // Types
    &.has-image {
        min-height: rem(300);

        @media (--from-normal-screen-w) {
            min-height: rem(600);
        }

        @media (--from-large-screen-w) {
            min-height: rem(700);
        }
    }


    // Elements
    &-media {
        @media (--from-small-screen-w) and (--to-normal-screen-w) {
            position: relative;

            height: rem(500);
        }

        @media (--from-normal-screen-w) {
            position: absolute;
            left: 50%;

            width: 50%;
            height: 100%;
        }


        // Types
        &.left-aligned {
            @media (--from-normal-screen-w) {
                right: 50%;
                left: auto;
            }
        }


        // Elements
        &::before {
            height: 50%;

            @media (--from-small-screen-w) and (--to-normal-screen-w) {
                position: absolute;
                bottom: 0;
                left: 0;

                width: 100%;
                height: 50%;

                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
                opacity: 0.6;
            }
        }

    }

    &-content {

        // Types
        &:not(.single) {
            padding-top: rem(45);
            padding-bottom: rem(45);

            @media (--from-small-screen-w) and (--to-normal-screen-w) {
                position: absolute;
                bottom: 0;

                padding-top: rem(40);
                padding-bottom: rem(40);

                background: none;
            }

            @media (--from-normal-screen-w) {
                position: absolute;
                right: 50%;

                height: 100%;
                max-width: calc(rem($constrain-width-large) / 2);
                padding-top: rem(50);
                padding-bottom: rem(50);
            }

            @media (--from-large-screen-w) {
                padding-right: rem(100);
                padding-left: rem($horizontal-spacing-x-large);
            }


            // Elements
            blockquote {
                text-align: left;

                @media (--from-normal-screen-w) {
                    position: relative;
                    top: 50%;

                    transform: translateY(-50%);
                }

                p {
                    @media (--from-small-screen-w) {
                        font-size: rem(28);
                    }

                    @media (--from-large-screen-w) {
                        font-size: rem(40);
                    }
                }
            }
        }

        &.single {
            padding-top: rem(50);
            padding-bottom: rem(50);

            @media (--from-medium-screen-w) {
                padding: rem(80);
            }
        }

        &.right-aligned {
            @media (--from-normal-screen-w) {
                right: auto;
                left: 50%;
            }

            @media (--from-large-screen-w) {
                padding-right: rem($horizontal-spacing-x-large);
                padding-left: rem(100);
            }
        }
    }

    blockquote p {
        margin-bottom: 0;

        color: $color-light;
    }

    em {
        color: $color-lime;
    }
}
