/*
    Button
*/
.button,
button.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: rem(45);
    min-width: rem(170);
    padding-right: rem(30);
    padding-left: rem(30);

    color: $color-light;
    font-family: $font-primary;
    font-size: rem(18);
    text-decoration: none;

    background-color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: rem(50);

    transition: background-color 0.2s, border-color 0.2s, color 0.2s, opacity 0.2s;

    @media (--from-medium-screen-w) {
        height: rem(48);
        padding-right: rem(50);
        padding-left: rem(50);
    }


    // States
    &:hover {
        @media (--from-medium-screen-w) {
            background-color: $color-primary-tint-1;
            border-color: $color-primary-tint-1;
        }
    }

    &:focus {
        outline: 0;
        outline-color: transparent;
        outline-style: none;
    }

    &:disabled,
    &.is-disabled {
        pointer-events: none;
        cursor: not-allowed;

        background-color: $color-light-shade-2;
        border-color: $color-light-shade-2;
    }

    &:active,
    &.is-active {
        background-color: $color-primary-shade-1;
        border-color: $color-primary-shade-1;
    }


    // Types
    &.block {
        display: block;
        width: 100%;
    }


    // Sizes
    &.small {
        height: rem(30);
        padding-right: rem(10);
        padding-left: rem(10);

        font-size: rem(14);

        .button-icon {
            width: rem(20);
            height: rem(20);
        }
    }

    &.large {
        @media (--from-medium-screen-w) {
            height: rem(60);
            min-width: rem(200);
            padding-right: rem(50);
            padding-left: rem(50);

            font-size: rem(22);
        }

        .button-icon {
            @media (--from-medium-screen-w) {
                width: rem(40);
                height: rem(40);
            }
        }
    }


    // Themes
    &.alternative {
        color: $color-primary;

        background-color: $color-light;


        // States
        &:hover {
            @media (--from-medium-screen-w) {
                color: $color-light;

                background-color: $color-primary;
                border-color: $color-primary;
            }
        }

        &:disabled,
        &.is-disabled {
            color: $color-light-shade-2;
        }

        &:active,
        &.is-active {
            color: $color-light;

            background-color: $color-primary-shade-1;
            border-color: $color-primary-shade-1;
        }
    }
}
