/*
    Carousel Pagination
 */
.carousel-pagination {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: rem($button-height-small);
    padding-right: rem(16);
    padding-left: rem(16);

    background-color: $overlay-dark-strong;

    @media (--from-small-screen-w) {
        height: rem($button-height);
    }

    @media (--from-normal-screen-w) {
        height: rem($button-height-large);
    }


    // Elements
    &-item {
        position: relative;

        display: block;
        width: rem(32);
        padding: 0;
        margin: 0;
        overflow: hidden;

        background-color: transparent;
        border: none;
        outline: none;


        // States
        &:hover {
            cursor: pointer;

            &::before {
                @media (--from-medium-screen-w) {
                    background-color: $overlay-light-strong;
                }
            }
        }

        &.is-active::after {
            opacity: 1;
        }


        // Elements
        &::before,
        &::after {
            position: absolute;
            top: 50%;
            left: 50%;

            display: block;
            width: rem(20);
            height: rem(20);

            border-radius: 50%;

            content: '';

            transform: translate(-50%, -50%);
            transition: background-color 0.15s ease-in-out;
        }

        &::before {
            background-color: $overlay-light-weak;
        }

        &::after {
            width: rem(12);
            height: rem(12);

            background-color: $color-light;

            opacity: 0;
        }

        &-title {
            // Aria hidden
            position: absolute;
            left: 100%;
        }
    }
}
