/*
    Tile Blocks
 */
.tile-block {
    padding-top: rem($vertical-spacing-medium);
    padding-bottom: rem($vertical-spacing-medium);
    overflow-x: hidden; // Reduces overflow on body when using negative margin

    @media (--from-medium-screen-w) {
        padding-top: rem($vertical-spacing);
        padding-bottom: rem($vertical-spacing);
    }

    @media (--from-normal-screen-w) {
        padding-top: rem($vertical-spacing-x-large);
        padding-bottom: rem($vertical-spacing-large);
    }


    // Elements
    &-inner {
        margin-right: rem(-$grid-spacing);
        margin-left: rem(-$grid-spacing);

        @media (--from-small-screen-w) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-title {
        margin-bottom: rem($vertical-spacing-medium);

        @media (--from-medium-screen-w) {
            margin-bottom: rem($vertical-spacing);
        }
    }

    .tile {
        @media(--to-small-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
            margin-bottom: rem(30);
        }

        @media (--from-small-screen-w) {
            flex-basis: calc(100% / 2);
            max-width: calc(100% / 2); // IE flex-wrap
        }

        @media (--from-normal-screen-w) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3); // IE flex-wrap
        }

        @media (--from-large-screen-w) {
            flex-basis: calc(100% / 4);
            max-width: calc(100% / 4); // IE flex-wrap
        }

        &-inner {
            @media (--to-small-screen-w) {
                border-bottom: 2px solid $color-mercury;
            }

            @media (--from-small-screen-w) {
                padding-right: rem(20);
                padding-left: rem(20);
            }
        }
    }

    &-view-more {
        display: flex;
        justify-content: space-around;
    }
}
