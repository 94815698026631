/*
    Notification
*/
.notification {
    position: relative;


    // Types
    &.alert {
        background-color: $color-alert;
    }

    &.info {
        background-color: $color-info;
    }


    // States
    &:not(.is-visible) {
        display: none;
    }


    // Elements
    &-inner {
        position: relative;

        display: flex;
        align-items: center;

        padding-top: rem(15);
        padding-bottom: rem(15);

        color: $color-white;

        @media (--to-small-screen-w) {
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &-content {
        @media (--to-small-screen-w) {
            order: 3;
            padding-top: rem(5);
            padding-left: 0;
        }

        flex-basis: 100%;
        padding-left: rem(16);
    }

    &-icon, &-icon-close {
        width: rem(32);
        height: rem(32);

        color: $color-light;
    }

    &-icon {
        @media(--from-small-screen-w) {
            position: absolute;
            top: rem(10);
            left: rem(35);
        }
    }

    &-toggle {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        color: $color-light;
        font-size: rem(11);
        text-transform: lowercase;

        @media(--from-small-screen-w) {
            position: absolute;
            top: 50%;
            right: rem(25);

            font-size: rem(12);

            transform: translateY(-50%);
        }
    }

    &-toggle-icon {
        width: rem(32);
        height: rem(32);
    }

    &-title {
        font-weight: $weight-bold;
    }

    .link {
        margin-bottom: 0;

        font-size: rem(18);

        @media (--to-small-screen-w) {
            font-size: rem(16);
        }

        &:hover {
            color: $color-white;
        }
    }

    p {
        margin-bottom: 0;

        line-height: 1.4;

        @media (--to-small-screen-w) {
            font-size: rem(16);
        }
    }

    a {
        display: inline-block;

        color: $color-white;
        text-decoration: underline;
    }
}
