/*
    Type
*/
html {
    color: $color-abbey;
    font-family: $font-primary;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-weight: $weight-regular;
    line-height: 1.44;
    word-break: break-word;
    word-wrap: break-word;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Reset
}


// Normal text
p {
    margin-top: 0;
    margin-bottom: rem(20);

    @media (--from-small-screen-w) {
        font-size: rem(18);
    }

    small {
        font-size: rem(12);

        @media (--from-small-screen-w) {
            font-size: rem(14);
        }
    }
}

b, strong {
    font-family: $font-primary-extrabold;
}

em {
    font-style: italic;
}


// Link
a {
    color: inherit;
    text-decoration: none;
}

p a,
li a,
button.link {
    &:not(.button) {
        padding-bottom: rem(2);

        text-decoration: none;

        border-bottom: rem(1) solid $color-primary-shade-1;

        transition: color 0.18s ease-in-out, border-color 0.18s ease-in-out;

        &:hover {
            color: $color-primary-shade-1;

            border-bottom-color: transparent;
        }
    }
}
