/*
    Quote
*/
blockquote,
q {
    display: flex;
    flex-direction: column;
    margin: 0;

    quotes: '“' '”' '‘' '’';

    @media (--from-medium-screen-w) {
        text-align: center;
    }


    // Types
    &.inverted {
        background-color: $color-primary-shade-1;
        border-top: none;
        border-bottom: rem(6) solid $color-primary;


        // Elements
        p {
            color: color($color-light alpha(90%));
        }

        p:first-child {
            color: $color-light;
        }
    }


    // Elements
    p {
        color: $color-midnight;
        font-family: $font-secondary;
        font-size: rem(28);
        line-height: 1.1;

        @media (--from-normal-screen-w) {
            font-size: rem(40);
        }
    }

    p:first-child {

        // Elements
        &::before {
            content: open-quote;
        }

        &::after {
            content: close-quote;
        }
    }

    em {
        order: -1;

        margin-bottom: rem(15);

        color: $color-primary-tint-1;
        font-size: rem(12);
        font-style: normal;
        font-weight: $weight-semi-bold;
        letter-spacing: rem(1);
        text-transform: uppercase;
    }
}
