/*
    Block Button
 */
.block-button,
button.block-button {
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;

    color: $color-light;

    background-color: $color-primary;
    border: none;
    outline: none;

    transition:
        opacity 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
    appearance: none;


    // States
    &:hover {
        @media (--from-medium-screen-w) {
            cursor: pointer;

            background-color: $color-primary-tint-1;
        }
    }


    // Types
    &.overlay {
        background-color: $overlay-dark-weak;

        &:hover {
            @media (--from-medium-screen-w) {
                background-color: $overlay-dark-strong;
            }
        }
    }

    &.overlay-dark {
        background-color: $overlay-dark-strong;

        &:hover {
            @media (--from-medium-screen-w) {
                background-color: rgba(0, 0, 0, 0.4);
            }
        }
    }


    // Elements
    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem($button-height-small);
        min-width: rem($button-height-small);
        padding-right: rem(5);
        padding-left: rem(5);

        @media (--from-small-screen-w) {
            height: rem($button-height);
            min-width: rem($button-height);
        }

        @media (--from-normal-screen-w) {
            height: rem($button-height-large);
            min-width: rem($button-height-large);
        }
    }

    &-label {
        flex-grow: 1;
        padding-right: rem(20);
        padding-left: rem(20);

        font-family: $font-primary;
        font-size: rem(16);
        letter-spacing: 0.02em;

        @media (--from-medium-screen-w) {
            font-size: rem(18);
        }


        // Order
        &:first-child {
            text-align: right;
        }

        &:last-child {
            text-align: right;
        }
    }

    .icon {
        width: 1em;
        height: 1em;

        font-size: rem(20);

        @media (--from-tiny-screen-w) {
            font-size: rem(24);
        }

        @media (--from-small-screen-w) {
            font-size: rem(30);
        }

        @media (--from-normal-screen-w) {
            font-size: rem(32);
        }


        // Order
        &:only-child {
            margin-right: rem(-5);
            margin-left: rem(-5);
        }

        &:last-child:not(:first-child) {
            margin-right: rem(10);

            @media (--from-medium-screen-w) {
                margin-right: rem(15);
            }
        }

        &:first-child:not(:last-child) {
            margin-left: rem(10);

            @media (--from-medium-screen-w) {
                margin-left: rem(15);
            }
        }
    }
}
