/*
    Call To Action
*/
.call-to-action {
    position: relative;

    .padding-top {
        padding-top: rem(80);
    }

    .padding-bottom {
        padding-bottom: rem(60);
    }

    // Elements
    &-inner {
        min-height: rem($section-height-medium);

        @media (--from-normal-screen-w) {
            display: flex;
            align-items: stretch;
            min-height: rem($section-height);
        }
    
        @media (--from-large-screen-w) {
            min-height: rem($section-height-large);
        }

    }

    &-media {
        min-height: rem($section-image-height);

        @media (--from-normal-screen-w) {
            order: 2;
            width: 50%;
        }


        // Types
        &.left-aligned {
            @media (--from-normal-screen-w) {
                order: 1;
            }
        }

        .icon {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $color-white;
            z-index: 1;

            &:hover {
                cursor: pointer;
            }
        }

        &.is-video {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
            }

            .icon {
                display: block;
            }
        }
    }

    &-content {
        @media (--from-normal-screen-w) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 50%;
        }

        @media (--from-large-screen-w) {
            order: 1;
        }

        .inner {
            padding: rem(45) rem($horizontal-spacing);

            @media (--from-small-screen-w) and (--to-normal-screen-w) {
                max-width: rem($constrain-width-small);
                padding-right: rem($horizontal-spacing-x-large);
                padding-left: rem($horizontal-spacing-x-large);
                margin-right: auto;
                margin-left: auto;
                max-width: rem($constrain-width-small);
            }

            @media (--from-normal-screen-w) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 rem(80) 0 rem($horizontal-spacing-x-large);
            }
    
            @media (--from-large-screen-w) {
                max-width: calc(rem($constrain-width-large) / 2);
            }
        }


        // Types
        &.right-aligned {
            @media (--from-normal-screen-w) {
                order: 2;
                justify-content: flex-start;
            }

            .inner {
                @media (--from-normal-screen-w) {
                    padding: 0 rem($horizontal-spacing-x-large) 0 rem(80);
                }
            }
        }


        // Elements
        ul {
            text-align: left;
        }
    }
}
