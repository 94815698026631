/*
    Content Blocks
 */
.content-blocks {

    // Elements
    .text-content-block {

        // Relationship
        + .button-block {

            // Elements
            .button-block-inner { // Can't use "&-" convention here as it includes the "+"
                padding-top: 0;
            }
        }
    }

    .content-set {

        // Relationship
        + .button-block {
            margin-top: rem(-1); // Overlap the content set bottom border

            background-color: $color-white;
            border-bottom: rem(1) solid $color-light-shade-1;


            // Elements
            .button-block-inner { // Can't use "&-" convention here as it includes the "+"
                padding-top: 0;
            }
        }
    }

    .file-download + .file-download {
        margin-top: rem(-$vertical-spacing);

        @media(--from-small-screen-w) {
            margin-top: rem(-$vertical-spacing-large)
        }


        // Elements
        .file-download-inner {
            border-top: none;
        }
    }
}
