/*
    Hero Carousel
*/
.hero-carousel {
    position: relative;


    // Elements
    &-inner {
        position: relative;
        z-index: $layer-hero-carousel;

        min-height: rem(550);
        overflow: hidden;

        @media (--from-tiny-screen-w) {
            min-height: rem(600);
        }

        @media (--from-small-screen-w) and (--to-medium-screen-w) {
            min-height: rem(750);
        }

        @media (--from-medium-screen-w) {
            max-height: calc(100vh - rem(40));
        }


        // Elements
        &::before {
            display: block;
            padding-top: calc(100% * 0.3);

            content: '';

            @media (--from-medium-screen-w) {
                padding-top: calc(100% * 0.6);
            }
        }
    }

    &-images {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;

        background-color: $color-dark;


        // Elements
        &::before,
        &::after {
            position: absolute;
            bottom: rem(50);
            left: 0;
            z-index: $layer-hero-carousel;

            display: block;
            width: 100%;
            height: 100%;

            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0.8) 100%);

            content: '';

            @media (--from-small-screen-w) {
                bottom: rem(60);
            }

            @media (--from-medium-screen-w) {
                bottom: 0;

                width: rem(600);
                height: rem(600);

                background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
            }

            @media (--from-normal-screen-w) {
                width: rem(900);
                height: rem(900);
            }

            @media (--from-x-large-screen-w) {
                width: rem(1100);
                height: rem(1100);
            }
        }

        &::after {
            width: 100%;
            height: 100%;

            background-image: linear-gradient(40deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);

            @media (--to-medium-screen-w) {
                display: none;
            }
        }
    }

    &-slide {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-position: 40% center;
        background-size: cover;
        opacity: 0;

        transform: scale(1.1);
        transition:
            opacity 0.5s ease-in-out,
            transform 1s ease-in-out;

        @media (--from-medium-screen-w) {
            background-position: center;
        }


        // States
        &.is-active {
            opacity: 1;

            transform: scale(1);
        }
    }

    &-intro {
        position: absolute;
        bottom: 0;
        left: 0;

        z-index: $layer-hero-carousel-content;

        width: 100%;
        padding-bottom: rem(60);

        @media (--from-small-screen-w) and (--to-medium-screen-w) {
            text-align: center;
        }

        @media (--from-medium-screen-w) {
            padding-bottom: rem(80);
        }
    }

    &-caption,
    &-lead {
        @media (--from-normal-screen-w) {
            padding-right: rem(60);
            padding-left: rem(60);
        }
    }

    &-lead {
        color: $color-light;
        font-family: $font-secondary;
        font-size: rem(30);
        text-shadow: 0 rem(1) rem(3) $overlay-dark-strong;

        @media (--from-small-screen-w) {
            font-size: rem(36);
        }

        @media (--from-medium-screen-w) {
            font-size: rem(40);
        }

        br {
            @media (max-width: 510px) {
                display: none;
            }
        }
    }

    &-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: $layer-hero-carousel-content;

        display: flex;
        justify-content: space-between;
        width: 100%;


        // Elements
        & > * {
            flex-grow: 0;
        }

        .block-button {

            // Order
            &:first-child {
                @media (--to-medium-screen-w) {
                    background-color: rgba(0, 0, 0, 0.8);
                }
            }

            &:last-child {
                @media (--to-medium-screen-w) {
                    flex-grow: 1;
                }

                @media (--from-medium-screen-w) {
                    flex-basis: rem(380);
                }
            }
        }
    }

    &-caption,
    &-next-action {
        position: relative;


        // Elements
        &-item {
            position: absolute;

            opacity: 0;

            transform: translateY(rem(20));
            transition:
                opacity 0.25s ease-in-out,
                transform 0.25s ease-in-out;


            // States
            &.is-active {
                opacity: 1;

                transform: translateY(0);

                transition:
                    opacity 0.25s ease-in-out 0.5s,
                    transform 0.25s ease-in-out 0.55s;
            }
        }
    }

    &-next-action {
        height: rem(60);

        &-item {
            top: 50%;
            right: rem(20);

            display: block;
            max-width: calc(100% - rem(40));
            margin-top: rem(-10);
            overflow: hidden;

            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &-caption {
        min-height: rem(40);
        margin-bottom: rem(20);

        @media (--to-medium-screen-w) {
            height: rem(70);
        }

        &-item {
            @media (--to-normal-screen-w) {
                width: 100%;
            }
        }
    }

    p {
        color: $color-light;

        @media (--from-normal-screen-w) {
            font-size: rem(20);
        }

        em {
            opacity: 0.8;

            @media (--to-medium-screen-w) {
                display: block;
                padding-top: rem(10);

                font-size: rem(14);
            }

            &::before {
                @media (--from-medium-screen-w) {
                    content: '– ';
                }
            }
        }

        br {
            @media (--from-medium-screen-w) {
                display: none;
            }
        }
    }
}
