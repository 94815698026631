/*
    Flexi Block
 */
.flexi-block {

    // Elements
    .inner {
        margin-right: rem(-$block-spacing-half);
        margin-left: rem(-$block-spacing-half);

        @media (--from-flexi-tablet-screen-w) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
