/*
    Accordion
*/
$--content-padding: 80;

.accordion {
    counter-reset: ordered-header;

    @media (--to-small-screen-w) {
        padding-top: rem($vertical-spacing);
        padding-bottom: rem($vertical-spacing);
    }

    @media (--from-small-screen-w) {
        padding-top: rem($vertical-spacing-large);
        padding-bottom: rem($vertical-spacing-large);
    }

    @media (--from-large-screen-w) {
        position: relative;

        display: flex;
        justify-content: flex-end;

        min-height: rem($section-height-large);
        padding-top: rem($vertical-spacing-x-large);
        padding-right: 50%;
        padding-bottom: rem($vertical-spacing-x-large);
    }


    // Elements
    &::before {
        @media (--from-large-screen-w) {
            position: absolute;
            top: 0;
            left: 50%;

            display: block;
            width: 50%;
            height: 100%;

            background-color: $color-primary-shade-2;

            content: '';
        }
    }

    &-inner {
        @media(--from-large-screen-w) {
            flex-basis: 100%;
            max-width: calc(rem($constrain-width-large) / 2);
        }
    }
}
