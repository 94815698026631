/*
    Dialog
*/
.dialog {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: $overlay-dark-strong;


    // States: ReactTransitionGroup – http://reactcommunity.org/react-transition-group/#CSSTransition
    &-enter {
        opacity: 0.01;

        &-active {
            opacity: 1;

            transition: opacity 0.3s ease-in; // Must match timeout.enter duration – dialog.jsx
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0.01;

            transition: opacity 0.25s ease-in; // Must match timeout.exit duration – dialog.jsx
        }
    }
}
