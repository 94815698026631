/*
    Search dialog
*/
.search-dialog {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: rem(80);

    &-form {
        width: rem(500);
        max-width: 100%;
        text-align: center;
        margin-bottom: rem(20);

        .heading {
            color: $color-dusty;
            text-transform: uppercase;
            margin-bottom: rem(20);
            font-size: rem(14);
        }

        input.input {
            border: none;
            border-bottom: rem(1) solid $color-light-shade-1;
            height: rem(80);
            width: 100%;
            font-family: $font-primary-extrabold;
            font-size: rem(32);
            padding-bottom: rem(20);
            text-align: center;

            @media (--from-medium-screen-w) {
                font-size: rem(64);
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .search-results-wrapper {
        width: 100%;
        text-align: center;
        .message {
            font-size: rem(16);
            margin-bottom: rem(20);

            @media (--from-medium-screen-w) {
                margin-bottom: rem(40);
            }
        }

        .pagination {
            text-align: center;
        }
    }

    .close-button {
        position: absolute;
        top: rem(20);
        right: rem(15);

        @media (--from-large-screen-w) {
            position: fixed;
        }
    }

    .center-loader {
        margin: 0 auto;
    }
}