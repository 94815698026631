/*
    Accordion Item
*/
.accordion-item {
    @media(--to-large-screen-w) {
        border-top: 1px solid $color-light-shade-1;
    }


    //States
    &.is-active {

        // Elements
        .icon-button,
        .accordion-item-title,
        .accordion-item-title:before {
            color: $color-primary;
        }

        .icon-button {
            transform: rotate(225deg);
        }

        .accordion-item-title::before {
            @media(--from-large-screen-w) {
                left: rem(-45);

                content: '/ ' counter(ordered-header);
            }
        }

        .accordion-item-content {
            z-index: $layer-faq-content-content-active;

            color: $color-light;

            opacity: 1;

            @media(--to-large-screen-w) {
                display: block;
            }


            // Elements
            &::before,
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: $layer-faq-content-gradient;

                display: block;
                width: 100%;
                height: rem(170);

                pointer-events: none;
                background-image: linear-gradient(180deg, $color-primary-shade-2 0%, $color-primary-shade-2 10%, color($color-primary-shade-2 alpha(0%)) 100%);

                content: '';
            }

            &::after {
                top: auto;
                bottom: 0;

                height: rem(60);

                background-image: linear-gradient(180deg, color($color-primary-shade-2 alpha(0%)), $color-primary-shade-2 90%, $color-primary-shade-2 100%);
            }

            &-wrapper {
                opacity: 1;

                transform: translateY(0);
                transition:
                    opacity 0.3s ease-in-out 0.15s,
                    transform 0.25s ease-in-out 0.15s;
            }

            &-container {
                @media(--from-large-screen-w) {
                    max-height: rem($section-height-large);
                    overflow-y: scroll;
                }
            }

            &-indicator {
                position: absolute;
                top: rem($vertical-spacing-x-large);
                left: rem($--content-padding);
                z-index: $layer-faq-content-indicator;

                color: $color-light;

                @media(--to-large-screen-w) {
                    display: none;
                }
            }

            &-inner {
                @media(--from-large-screen-w) {
                    display: flex;
                    align-items: center;
                    min-height: rem($section-height-large);
                }
            }

            &-description {
                @media(--from-large-screen-w) {
                    max-width: calc(rem($constrain-width-large) / 2);
                    padding-right: rem($horizontal-spacing-x-large);
                    padding-left: rem($--content-padding);
                    margin: 0;
                }
            }
        }

        .accordion-item-header:after {
            position: absolute;
            right: rem(30);
            bottom: 0;

            width: 0;
            height: 0;

            border: rem(10) solid transparent;
            border-bottom: rem(10) solid $color-primary-shade-2;

            content: '';

            @media(--from-small-screen-w) {
                right: rem(60);
            }

            @media(--from-large-screen-w) {
                right: 0;
                bottom: auto;

                border-right: rem(10) solid $color-primary-shade-2;
                border-bottom: rem(10) solid transparent;
            }
        }
    }


    // Types
    &:last-child {
        @media (--to-large-screen-w) {
            border-bottom: 1px solid $color-light-shade-1;
        }
    }


    // Elements
    &-header {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: rem(40);
        padding: rem(20) 0;
        overflow: hidden;

        text-align: left;

        background-color: transparent;
        border: none;

        appearance: none;


        // States
        &:hover {
            cursor: pointer;
        }


        // Elements
        .icon-button {
            transition:
                transform 0.2s ease-in-out,
                color 0.2s ease-in-out;

            @media(--from-large-screen-w) {
                display: none;
            }
        }
    }

    &-title {
        position: relative;

        margin-bottom: 0;

        transition: color 0.2s ease-in-out;

        @media(--to-large-screen-w) {
            padding-right: rem(30);
        }


        // Elements
        &:before {
            @media(--from-small-screen-w) {
                position: absolute;
                top: rem(1);
                left: rem(-25);

                color: rgb(208, 208, 208);
                font-size: rem(18);

                content: counter(ordered-header);
                counter-increment: ordered-header;

                transition: color 0.2s ease-in-out;
            }

            @media(--from-large-screen-w) {
                left: rem(-35);
            }
        }
    }

    &-content {
        z-index: $layer-faq-content-content;

        background-color: $color-primary-shade-2;
        opacity: 0;

        @media(--to-large-screen-w) {
            display: none;
        }

        @media(--from-large-screen-w) {
            position: absolute;
            top: 0;
            left: 50%;

            width: 50%;
            height: 100%;
        }


        // Elements
        &-wrapper {
            opacity: 0;

            transform: translateY(rem(20));
            transition:
                opacity 0.25s ease-in-out 0.1s,
                transform 0.25s ease-in-out 0.1s;
        }

        &-inner {
            padding-top: rem(40);
            padding-bottom: rem(40);

            @media(--from-large-screen-w) {
                padding-top: rem(155);
                padding-bottom: rem(155);
            }
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}
