/*
    Full screen dialog
*/
.full-screen-dialog {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-white;
    z-index: $layer-full-screen;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: rem(100);
    overflow: scroll;

    &.theme-grey {
        background-color: $color-porcelain;
    }

    button.action-button {
        position: fixed;
        top: rem(20);
        left: rem(20);
        background-color: transparent;
        
        .icon {
            width: rem(30);
            height: rem(30);
        }
    }
}