.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 60%);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;
    
    .inner {
        position: relative;
        background-color: $color-white;
        width: 100%;
        max-width: 100%;
        min-height: 200px;
        
        @media (--from-medium-screen-w) {
            width: 1000px;
            max-width: 90%;
        }
    }
    
    .close-btn {
        position: absolute;
        cursor: pointer;
        top: 30px;
        right: 10px;
        color: $color-white;
        
        @media (--from-medium-screen-w) {
            right: 30px;
        }
    }
    
    &.container-small {
        .inner {
            @media (--from-medium-screen-w) {
                width: 500px;
            }
        }
    }

    .youtube-player {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%; // Aspect ratio 16:9
        height: 0;

        iframe#player {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}