/*
    Navigation
 */
.navigation {
    @media (--from-navigation-breakpoint) {
        display: flex;
        align-items: center;
    }


    // Elements
    &-item {
        position: relative;

        @media (--to-navigation-breakpoint) {
            display: flex;
            flex-wrap: wrap;

            border-bottom: rem(1) solid $overlay-light-weak;
        }

        @media (--from-navigation-breakpoint) {
            display: flex;
            align-items: center;
            margin-right: rem(40);
        }


        // States
        &:hover,
        &.is-active {
            .sub-navigation {
                @media (--from-navigation-breakpoint) {
                    height: auto;
                    overflow: visible;

                    opacity: 1;

                    transform: translate(-50%, 0);
                    transition:
                        transform 0.2s ease-in-out,
                        opacity 0.2s ease-in-out;
                }
            }
        }

        &:hover .sub-navigation-toggle .icon {
            @media (--from-navigation-breakpoint) {
                transform: rotate(180deg);
            }
        }

        &.is-active {
            .sub-navigation {
                @media (--to-navigation-breakpoint) {
                    display: block;
                    max-height: rem(1000);
                }
            }

            .sub-navigation-toggle .icon {
                transform: rotate(180deg);
            }
        }
    }

    &-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        height: rem(50);
        padding-right: rem(20);
        padding-left: rem($horizontal-spacing-medium);
        color: $color-light;
        font-size: rem(18);
        font-weight: $weight-regular;
        line-height: 1;
        letter-spacing: 0.01em; // Increase light on dark
        text-decoration: none;

        @media (--from-navigation-breakpoint) {
            flex-direction: column;
            justify-content: center;
            height: rem(60);
            padding: 0;
        }            

        // States
        &:hover,
        &.is-active {
            @media (--from-navigation-breakpoint) {
                color: color($color-light alpha(60%));
            }

            & + .sub-navigation-toggle .icon {
                @media (--from-navigation-breakpoint) {
                    color: color($color-light alpha(60%));
                }
            }
        }

        .label,
        .te-reo {
            @media (--from-navigation-breakpoint) {
                width: 100%;
            }
        }

        .label {
            font-weight: $weight-bold;
            order: 1;

            &:after {
              content: '|';
              display: inline-block;
              margin: 0 rem(10);
            }

            @media (--from-navigation-breakpoint) {
                order: 2;

                &:after {
                    content: '';
                }
            }
        }

        .te-reo {
            color: color($color-light alpha(80%));
            order: 2;

            @media (--from-navigation-breakpoint) {
                order: 1;
                margin-bottom: rem(10);
            }
        }
    }

    &-toggle {
        @media (--from-navigation-breakpoint) {
            display: none;
        }
    }

    .sub-navigation-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: rem(24);
        height: rem(40);
        padding: 0;

        color: inherit;
        text-align: right;

        background: transparent;
        border: none;

        @media (--to-navigation-breakpoint) {
            flex-grow: 100;
            justify-content: flex-end;
            height: rem(50);
            padding-right: rem(20);
            margin-right: rem(10);
        }


        // Elements
        .icon {
            width: rem(14);
            height: rem(14);

            color: $color-light;

            transition: transform 0.2s ease-in-out;
        }
    }
}
