/*
    Image Carousel
*/
.image-carousel {
    margin-bottom: rem($vertical-spacing);

    @media (--from-small-screen-w) {
        margin-top: rem($vertical-spacing);
    }

    @media (--from-normal-screen-w) {
        margin-top: rem($vertical-spacing-large);
    }


    // Elements
    &-inner {
        @media (--from-normal-screen-w) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }


        // Types
        &.constrain-width {
            @media (--to-small-screen-w) {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .image-carousel-header {
            width: 100%;
        }

        // Elements
        .carousel {
            flex-basis: 100%;
        }
    }

    &-navigation {
        @media (--from-normal-screen-w) {
            flex-basis: 50%;
            order: 2;
        }
    }

    &-next-action {
        position: relative;

        display: block;


        // Elements
        &-item {
            position: absolute;
            top: 50%;
            right: rem(20);

            display: block;
            max-width: calc(100% - rem(40));
            margin-top: rem(-10);
            overflow: hidden;

            text-overflow: ellipsis;
            white-space: nowrap;

            opacity: 0;

            transform: translateY(rem(20));
            transition:
                opacity 0.25s ease-in-out,
                transform 0.25s ease-in-out;


            // States
            &.is-active {
                opacity: 1;

                transform: translateY(0);

                transition:
                    opacity 0.25s ease-in-out 0.5s,
                    transform 0.25s ease-in-out 0.55s;
            }
        }
    }

    &-content {
        position: relative;

        width: 100%;
        height: rem(200);
        margin-top: rem($vertical-spacing); // Only using margin because of absolute positioning

        @media (--from-normal-screen-w) {
            flex-basis: 50%;
            margin-top: rem($vertical-spacing-large);
        }


        // Elements
        &-item {
            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;

            transform: translateY(rem(20));
            transition:
                opacity 0.25s ease-in-out,
                transform 0.25s ease-in-out;


            // States
            &.is-active {
                z-index: $layer-image-carousel-active-slide;

                opacity: 1;

                transform: translateY(0);
                transition:
                    opacity 0.25s ease-in-out 0.5s,
                    transform 0.25s ease-in-out 0.55s;

            }
        }
    }

    .carousel-pagination {
        @media (--to-small-screen-w) {
            display: none; // Hide for mobile
        }
    }
}
