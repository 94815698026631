/*
    List
    - Ordered
    - Unordered
    - List item
*/
$--ol-bullet-size: 18;

ul,
ol {
    padding-left: rem(27);
    margin-top: 0;
    margin-bottom: rem(20);
}

li {
    position: relative;

    margin-bottom: rem(8);

    @media (--from-small-screen-w) {
        font-size: rem(18);
    }

    &::before {
        position: absolute;
        left: rem(-10);

        display: block;

        border-radius: rem(7.5);

        content: '';

        transform: translateX(-100%);
    }
}

ol {
    list-style: none;
    counter-reset: ordered-list;

    li::before {
        top: rem(5);

        width: rem($--ol-bullet-size);
        height: rem($--ol-bullet-size);

        color: $color-dark;
        font-size: rem(13);
        line-height: 1.6;
        text-align: center;

        background-color: $color-tertiary;

        content: counter(ordered-list);
        counter-increment: ordered-list;
    }
}

ul {
    list-style: none;

    li::before {	
        top: rem(6);
        content: '';
        width: rem(14);
        height: rem(14);
        border: rem(4) solid $color-tertiary;
        border-radius: rem(4);
        transform: translateX(-100%) rotate(45deg);
    }
}
