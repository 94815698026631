/*
    Logo
 */
.logo {
    display: block;
    width: 2.25em; // Ratio: 2.25/1
    height: 1em;

    color: $color-primary-shade-2;
    font-size: rem(30);

    @media (--from-small-screen-w) {
        font-size: rem(36);
    }

    @media (--from-navigation-breakpoint) {
        font-size: rem(40);
    }


    // Types
    &.type-long {
        width: 4.27em; // Ratio: 4.27/1
        height: 1em;

        font-size: rem(50);

        @media (--from-navigation-breakpoint) {
            font-size: rem(60);
        }
    }
}
