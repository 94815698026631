/*
    Heading
*/
h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
    margin-top: 0;
    margin-bottom: rem(20);

    color: $color-primary;

    font-family: $font-primary-semibold;
    line-height: 1.16;
}


// Headings
h1,
.heading-1 {
    margin-bottom: rem(24);

    font-size: rem(36);
    line-height: 1.22;

    @media (--from-small-screen-w) {
        font-size: rem(48);
    }
}

h2,
.heading-2 {
    margin-bottom: rem(16);

    font-size: rem(26);
    line-height: 1.22;

    @media (--from-small-screen-w) {
        font-size: rem(36);
    }
}

h3,
.heading-3 {
    font-size: rem(24);
    line-height: 1.25;

    @media (--from-small-screen-w) {
        font-size: rem(32);
        line-height: 1.18;
    }
}

h4,
.heading-4 {
    font-size: rem(22);

    @media (--from-small-screen-w) {
        font-size: rem(24);
        line-height: 1.09;
    }
}

h5,
.heading-5 {
    font-size: rem(18);
    line-height: 1.33;

    @media (--from-small-screen-w) {
        font-size: rem(20);
        line-height: 1.2;
    }
}

h6,
.heading-6 {
    margin-bottom: rem(15);

    font-size: rem(12);
    font-weight: $weight-semi-bold;
    line-height: 1.25;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @media (--from-small-screen-w) {
        font-size: rem(14);
        line-height: 1.38;
    }
}
