/*
    Line
 */
hr {
    width: rem($hr-width);
    height: rem(2);
    max-width: 100%;
    margin-right: auto;
    margin-bottom: rem(40);
    margin-left: 0;

    background-color: $color-primary-shade-1;
    border: 0;

    &.small {
        width: rem($hr-width-small);
    }
}
