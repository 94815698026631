/*
    Header
*/
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer-header;

    width: 100%;


    // Elements
    &-wrapper {
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: rem(50);

            background-color: $overlay-dark-extrastrong;

            content: '';

            transition:
                opacity 0.2s ease-in-out 0.1s,
                transform 0.2s ease-in-out 0.1s; // Add delay

            @media (--from-navigation-breakpoint) {
                height: rem($navigation-height-desktop);
            }
        }
    }


    &-inner {
        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;


        // Types
        &.constrain-width {
            @media (--from-small-screen-w) and (--to-large-screen-w) {
                padding-right: rem($horizontal-spacing);
                padding-left: rem($horizontal-spacing);
            }

            @media (--from-large-screen-w) {
                padding-right: rem($horizontal-spacing-x-large);
                padding-left: rem($horizontal-spacing-x-large);
            }
        }


        // Elements
        > * {
            flex-grow: 0;
        }
    }

    &-actions {
        z-index: $layer-header-logo;

        display: flex;
        justify-content: flex-end;
        order: 2;
        height: rem(80);

        @media (--from-small-screen-w) {
            height: rem(100);
        }

        @media (--from-navigation-breakpoint) {
            height: rem(120);
        }
    }

    &-logo {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: $color-primary-tint-1;

        width: rem(200);
        height: 100%;

        padding: 0 5px;

        @media (--from-navigation-breakpoint) {
            width: rem(280);
            padding: 0 10px;
        }

        // Elements
        img {
            width: 100%;
            max-height: 100%;
        }
    }

    .navigation {
        z-index: $layer-navigation;

        flex-grow: 1;
    }

    .icon-button {
        color: $color-light;
    }

    .notifications-button {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        width: rem(50);
        height: rem(50);
        margin-right: rem(8);

        @media (--from-navigation-breakpoint) {
            height: rem($navigation-height-desktop);
        }

        &.is-active {
            &::before {
                position: absolute;
                top: rem(14);
                right: rem(14);

                width: rem(11);
                height: rem(11);

                background-color: $color-secondary;
                border: rem(1) solid $color-light;
                border-radius: 50%;

                content: '';

                @media (--from-navigation-breakpoint) {
                    top: rem(38);
                }
            }
        }

        &.is-hidden {
            display: none;
        }

        .icon {
            width: rem(30);
            height: rem(30);
        }
    }
}
