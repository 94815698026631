/*
    Carousel Navigation
 */
.carousel-navigation {
    position: relative;


    // Elements
    &-actions {
        position: relative;

        display: flex;
        width: 100%;
        overflow: hidden;


        // Elements
        .block-button:last-child {
            flex-grow: 1;
        }
    }

    &-content {
        position: relative;

        padding-top: rem($vertical-spacing-medium);
        padding-bottom: rem($vertical-spacing-small);

        color: $color-light;

        background-color: color($color-dark alpha(50%));

        @media (--from-small-screen-w) {
            padding-top: rem($vertical-spacing);
            padding-bottom: rem($vertical-spacing-medium);
        }
    }

    &-creditline {
        position: relative;


        // Elements
        &-item {
            display: none;

            @media (--from-normal-screen-w) {
                position: absolute;

                opacity: 0;

                transform: translateY(rem(20));
                transition:
                    opacity 0.25s ease-in-out,
                    transform 0.25s ease-in-out;

            }

            // States
            &.is-active {
                z-index: $layer-carousel-navigation-content-active;

                display: block;

                @media (--from-normal-screen-w) {
                    opacity: 1;

                    transform: translateY(0);

                    transition:
                        opacity 0.25s ease-in-out 0.5s,
                        transform 0.25s ease-in-out 0.55s;
                }
            }
        }
    }

    &-background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-carousel-navigation-background;

        width: 100%;
        height: 100%;
        overflow: hidden;

        background-color: $color-dark;


        // Elements
        &-item {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background-position: center;
            background-size: 200% auto;
            opacity: 0;
            filter: blur(rem(20)) saturate(250%);

            transform: scale(2);
            transition:
                opacity 0.5s ease-in-out,
                transform 1s ease-in-out;


            @media (--from-medium-screen-w) {
                filter: blur(rem(30)) saturate(300%);
            }


            // States
            &.is-active {
                opacity: 0.85;

                transform: scale(1.5);
            }

            &.is-next {
                opacity: 0;
            }
        }
    }
}
