/*
    Image Gallery Double
*/
.image-gallery-double {
    max-width: rem($constrain-width-x-large);
    padding-bottom: rem($button-height); // Padding for the actions
    margin-right: auto;
    margin-left: auto;

    @media (--to-medium-screen-w) {
        display: none;
    }

    @media (--from-normal-screen-w) {
        padding-bottom: rem($button-height-large); // Padding for the actions
    }


    // Elements
    &-grid {
        display: flex;


        // Types
        &.primary {

            .image-gallery-double-content {
                display: flex;
                align-items: flex-end;
                flex-direction: row;

                @media (--from-normal-screen-w) {
                    padding-bottom: rem($button-height-large);
                }
            }
        }

        &.secondary {
            @media (--from-normal-screen-w) {
                margin-top: calc(rem(-$button-height-large));
            }


            // Elements
            .image-gallery-double {

                &-image {
                    min-height: calc(rem(400) + rem($button-height)); // Allow for natural overlap

                    @media (--from-normal-screen-w) {
                        flex-basis: 60%;
                        height: 50vh;
                        max-height: rem(600);
                        min-height: rem(500);
                    }
                }

                &-content {
                    display: flex;
                    flex-direction: column;

                    @media (--from-normal-screen-w) {
                        padding-top: calc(2 * rem($button-height-large));
                    }


                    // Elements
                    &-inner {
                        position: absolute;
                        top: rem($button-height);
                        right: 0;
                        bottom: auto;
                        z-index: $layer-image-gallery-double-content;

                        width: 100%;
                        height: calc(100% - (2 * rem($button-height)));

                        background-color: $color-light;

                        @media (--from-normal-screen-w) {
                            top: calc(2 * rem($button-height-large));
                            right: calc(2 * rem(-$button-height-large));

                            width: calc(100% + (2 * rem($button-height-large)));
                            height: calc(100% - (2 * rem($button-height-large)));
                        }
                    }

                    &-item {
                        position: absolute;
                        top: 0;
                        right: 0;

                        width: 100%;
                    }

                    .image-gallery-double-actions {
                        justify-content: flex-end;
                    }
                }

                &-actions {
                    z-index: $layer-image-gallery-double-actions;
                }
            }
        }
    }

    &-content {
        position: relative;

        flex-basis: 100%; // Fill the extra area


        // Elements
        &-item {
            position: absolute;

            width: 100%;

            opacity: 0;

            transform: translateY(rem(20));
            transition:
                opacity 0.25s ease-in-out,
                transform 0.25s ease-in-out;

            @media (--from-normal-screen-w) {
                bottom: rem($button-height-large);
            }


            // States
            &.is-active {
                z-index: $layer-image-gallery-double-content-active;

                opacity: 1;

                transform: translateY(0);

                transition:
                    opacity 0.25s ease-in-out 0.5s,
                    transform 0.25s ease-in-out 0.55s;
            }
        }
    }

    &-image {
        flex-basis: 50%;
        flex-shrink: 0;
        min-height: rem(400);

        @media (--from-normal-screen-w) {
            flex-basis: 40%;
            height: 50vh;
            max-height: rem(400);
            min-height: rem(400);
        }


        // Elements
        .carousel {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }

        .image-gallery-double-actions {
            top: 0;
            bottom: auto;
            left: 0;

            width: 100%;

            @media (--from-normal-screen-w) {
                top: 100%;
            }

            @media (--from-large-screen-w) {
                top: auto;
                bottom: 0;
            }
        }
    }

    &-caption {
        flex-basis: 50%;

        background-color: $color-light;

        @media (--from-normal-screen-w) {
            flex-basis: 60%;
        }
    }

    &-actions {
        position: absolute;
        right: 0;

        display: flex;
        justify-content: space-between;
        width: 100%;
        height: rem($button-height);

        @media (--from-normal-screen-w) {
            top: rem($button-height-large);

            height: rem($button-height-large);
        }
    }

    .rte-content {
        max-width: rem(700);
        padding-top: rem(40);
        padding-right: rem(60);
        padding-bottom: rem(40);
        padding-left: rem(60);

        p {
            @media (--to-normal-screen-w) {
                font-size: rem(16); // Reduce the font-size temporarily for tablet
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .carousel-pagination {
        min-width: calc(2 * rem($button-height));

        @media (--from-normal-screen-w) {
            min-width: calc(2 * rem($button-height-large));
        }
    }
}
