/*
    Form
    - Button
    - Input
    - Textarea
    - Select
 */
// Button
button,
button[type='submit'] {
    padding: 0;

    line-height: 1;

    cursor: pointer;
    background: none;
    border: none;
    border-radius: 0;

    appearance: none;

    &:focus {
        outline: 0;
        outline-color: transparent;
        outline-style: none;
    }
}


// Input
input[type='email'],
input[type='password'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'] {
}


// Textarea
textarea {
    max-height: rem(400);
    min-height: rem(100);
    padding-top: rem(7); // Aligns textarea text in the same position as a text input field

    line-height: inherit;
}


// Select
select {
    height: rem(40);
}
