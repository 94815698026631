.search-grids-tile {

    text-align: left;
    padding-top: rem(20);
    padding-bottom: rem(20);

    @media(--to-small-screen-w) {
        padding-right: rem(20);
        padding-left: rem(20);
        margin-bottom: rem(30);
    }

    @media (--from-small-screen-w) {
        flex-basis: calc(100% / 2);
        max-width: calc(100% / 2); // IE flex-wrap
    }

    @media (--from-normal-screen-w) {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3); // IE flex-wrap
    }

    @media (--from-large-screen-w) {
        flex-basis: calc(100% / 4);
        max-width: calc(100% / 4); // IE flex-wrap
    }

    &-inner {
        @media (--to-small-screen-w) {
            border-bottom: 2px solid $color-mercury;
        }

        @media (--from-small-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
        }
    }

    // States
    &:hover {
        @media (--from-normal-screen-w) {
            .tile-thumbnail {
                opacity: 0.5;
            }

            .text-link {
                opacity: 1;
            }
        }
    }
}