/*
    Footer
*/
.footer {
    background-color: $color-primary-tint-1;


    // Elements
    &-section {
        padding-top: rem($vertical-spacing);
        padding-bottom: rem($vertical-spacing-medium);

        @media (--from-medium-screen-w) {
            padding-top: rem($vertical-spacing-large);
            padding-bottom: rem($vertical-spacing);
        }


        // Type
        &:not(:last-child) {
            border-bottom: rem(1) solid $overlay-light-weak;
        }

        .constrain-width {
            @media (--from-small-screen-w) {
                padding-right: rem($horizontal-spacing-large);
                padding-left: rem($horizontal-spacing-large);
            }

            @media (--from-large-screen-w) {
                padding-right: rem($horizontal-spacing-x-large);
                padding-left: rem($horizontal-spacing-x-large);
            }
        }

        &.supplementary-color {
            background-color: $color-supplementary-navy;
        }

        &.padding-small {
            padding-top: rem($vertical-spacing-small);
            padding-bottom: rem($vertical-spacing-small);
        }
    }

    &-head {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        img {
            width: rem(80);
        }
    }

    &-main {
        @media (--from-medium-screen-w) {
            display: flex;
            justify-content: space-between;
        }



        // Elements
        &-column {
            @media (--from-medium-screen-w) {
                flex-basis: calc(100% / 2 - rem(20));
                flex-grow: 0;
            }

            &:not(:first-child) {
                padding-left: rem(20);
            }


            p:not(:last-child) {
                margin-bottom: 0;
            }


        }

        .social-links {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media (--to-medium-screen-w) {
                margin-bottom: rem(20);       
                width: 100%;         
            }

            &-item {
                color: $color-primary-tint-1;
                margin-right: rem(30);
                width: rem(40);
                height: rem(40);
                transition: color 0.18s ease-in-out;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: $color-primary;
                }
            }
        }

        .logo {
            width: rem(200);
            max-width: 100%;
            height: auto;
        }

        .flex-logo {
            @media (--to-medium-screen-w) {
                display: flex;
                margin-bottom: rem(40);
                justify-content: space-evenly;
            }
        }


        .toitu-icon {
            display:none;

            @media (--from-medium-screen-w) { 
                display: block;
            }
        }
        .m-toitu-icon {
            @media (--from-medium-screen-w) { 
                display: none;
            }
        }
    }

    &-outro {
        display: flex;
        justify-content: center;
        margin-bottom: rem(10);

        // Elements
        & > * {
            flex-grow: 0;
        }

        .copyright {
            margin-bottom: 0;
            color: $color-supplementary-navy;

            text-align: center;

            @media (--from-medium-screen-w) {
                display: flex;
                align-items: center;
            }


            // Elements
            &-text-spacer {
                padding: 0 rem(10);

                @media (--to-medium-screen-w) {
                    display: none;
                }
            }
        }

        a.link {
            padding-top: rem(5);
            padding-bottom: 0;
            margin-bottom: 0;

            color: $color-supplementary-navy;
            font-size: rem(12);

            @media (--from-small-screen-w) {
                padding-top: 0;

                font-size: rem(14);
            }
        }

    }

    .collapsible-content {

        // Elements
        &-title {
            color: $color-tertiary;
        }

        .icon {
            color: $color-primary-tint-2;
        }
    }

    .link {
        display: block;
        margin-bottom: rem(15);

        color: $color-light;
        font-size: rem(18);

        &:hover {
            color: color($color-light alpha(70%));
        }
    }

    p {
        color: $color-light;
    }

    p a:not(.button),
    li a:not(.button) {
        border: none;

        &:hover {
            color: color($color-light alpha(70%));
        }
    }
}
