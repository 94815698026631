/*
    Image Gallery Single
*/
.image-gallery-single {
    @media (--from-medium-screen-w) {
        display: none;
    }


    // Elements
    &-content-wrapper {
        position: relative;
    }

    .carousel-navigation {
        position: relative;


        // Elements
        &-content {
            @media (--from-normal-screen-w) {
                height: rem(300);
            }
        }

        &-actions {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: $layer-image-gallery-single-actions;

            display: flex;
            justify-content: center;
            width: 100%;


            // Elements
            .carousel-pagination {
                flex-grow: 1;
                justify-content: center;
            }

            .block-button:last-child {
                flex-grow: 0;
            }
        }
    }
}
