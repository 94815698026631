/*
    Loader
 */
.loader {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(60);
    height: rem(60);


    // Types
    &.block {
        width: 100%;
    }

    &.primary {
        color: $color-primary;
    }

    &.small {
        width: rem(30);
        height: rem(30);

        // Elements
        .loader-spinner {
            width: rem(20);
            height: rem(20);
        }
    }


    // Elements
    .loader-spinner {
        display: block;
        width: rem(40);
        height: rem(40);

        border: rem(2) solid currentColor;
        border-bottom-color: transparent;
        border-radius: 50%;

        content: '';
    }

    &-text {
        display: none;
    }
}
