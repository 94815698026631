/*
    Style Guide
 */
$--grid-spacing: 10;

.style-guide {

    // Elements
    &-header {
        padding-top: rem(100);
    }

    &-section {
        margin-bottom: rem(40);

        @media (--from-normal-screen-w) {
            margin-bottom: rem(100);
        }

        &:first-of-type {
            padding-top: rem(40);

            @media (--from-normal-screen-w) {
                padding-top: rem(50);
            }
        }

        &-header {
            margin-bottom: rem(40);
        }

        &-title {
            display: flex;

            font-size: rem(20);
            letter-spacing: 0.1em;
            text-transform: uppercase;

            &::after {
                display: block;
                flex-grow: 1;
                height: rem(2);
                margin-top: rem(10);
                margin-left: rem(10);

                background-color: $color-light-shade-1;

                content: '';
            }
        }

        ul {
            margin-bottom: rem(32);
        }
    }

    &-swatches {
        margin-right: calc(rem(-$--grid-spacing) / 2);
        margin-bottom: rem($--grid-spacing);
        margin-left: calc(rem(-$--grid-spacing) / 2);

        @media (--from-tiny-screen-w) {
            display: flex;
            flex-wrap: wrap; // IE requires max-width on children
        }


        // Elements
        .swatch {
            flex-basis: 100%;
            padding-right: calc(rem($--grid-spacing) / 2);
            padding-left: calc(rem($--grid-spacing) / 2);
            margin-bottom: rem($--grid-spacing);

            @media (--from-small-screen-w) {
                flex-basis: calc(100% / 3);
                max-width: calc(100% / 3);
            }

            @media (--from-medium-screen-w) {
                flex-basis: calc(100% / 4);
                max-width: calc(100% / 4);
            }
        }
    }

    &-button-group {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rem(40);


        // Elements
        .button,
        .block-button,
        .text-link {
            flex-shrink: 0;
            margin-right: rem(10);
            margin-bottom: rem(10);

            vertical-align: top;
        }
    }

    &-line {
        @media (--from-medium-screen-w) {
            margin-bottom: rem(20);

            border-bottom: rem(10) solid $color-primary-shade-1;
        }


        // Sizes
        &.x-large::after {
            content: ': ' '$constrain-width-x-large' 'px';
        }

        &.large::after {
            content: ': ' '$constrain-width-large' 'px';
        }

        &.medium::after {
            content: ': ' '$constrain-width-medium' 'px';
        }

        &.small::after {
            content: ': ' '$constrain-width-small' 'px';
        }


        // Elements
        &::after {
            content: ': ' '$constrain-width' 'px';
        }
    }

    &-logo {
        display: inline-block;
        margin-right: rem(40);
        margin-bottom: rem(20);

        color: $color-primary-shade-2;

        img {
            width: rem(220);
        }
    }

    &-icons {
        display: flex;

        color: $color-dark;


        // Elements
        .icon {
            flex-shrink: 0;
            margin-right: rem(10);
        }
    }

    &-specs {
        display: block;

        padding-top: rem(10);
        margin-bottom: rem(10);

        color: $color-dark-tint-1;
        font-size: rem(12);
        text-align: center;

        &.image-gallery {
            margin-bottom: rem(25);
        }
    }
}
