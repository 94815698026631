/*
    Carousel
*/
.carousel {
    position: relative;

    overflow: hidden;

    background-color: $color-dark;


    // Elements
    &-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-inner {
        position: relative;

        width:100%;
    }

    &-media {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        opacity: 0;

        transform: scale(1.1);

        transition:
            opacity 0.5s ease-in-out,
            transform 1s ease-in-out;


        // States
        &.is-active {
            z-index: $layer-image-carousel-active-slide;

            opacity: 1;

            transform: scale(1);
        }

    }

    .preserve-aspect-ratio {
        overflow: hidden;
    }
}
