/*
    Navigation Bar
 */
.navigation-bar {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    padding-top: rem(10);

    transition:
        opacity 0.2s ease-in-out 0.1s,
        transform 0.2s ease-in-out 0.1s; // Add delay

    @media (--from-navigation-breakpoint) {
        position: relative;
        padding-top: rem(20);
    }


    // States
    &.is-active &-inner {
        transform: translateX(0);
        transition: transform $overlay-transition-open;
    }

    &.is-hidden {
        opacity: 0;

        transition:
            opacity 0.2s ease-in-out,
            transform 0.2s ease-in-out; // No delay

        @media (--from-navigation-breakpoint) {
            transform: translateY(-50%);
        }
    }


    // Elements
    &-inner {
        @media (--to-navigation-breakpoint) {
            position: fixed;
            top: rem(50);
            left: 0;

            width: 100%;
            height: calc(100vh - rem(50));
            padding-top: rem(50);
            padding-bottom: rem(20);
            overflow-y: scroll;

            color: $color-light;

            background-color: $color-supplementary-navy;

            transform: translateX(-100%);
            transition: transform $overlay-transition-close;
        }

        @media (--from-navigation-breakpoint) {
            display: flex;
            align-items: center;
        }
    }
}
