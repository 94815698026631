/*
    Input
*/
.input {
    width: 100%;
    height: rem(40);
    max-width: 100%;

    color: $color-primary-shade-2;
    font-family: $font-primary;
    font-size: rem(18);

    background-color: transparent;
    border: 1px solid $color-light-shade-2;
    border-radius: rem(4);

    appearance: none;


    // States
    &:focus {
        border-color: $color-primary-tint-2;
        box-shadow: 0 rem(2) rem(10) 0 $color-light-shade-1;
        outline: 0;
    }

    &:disabled,
    &.is-disabled {
        cursor: not-allowed;
        background: $color-light-shade-2;
    }

    &.is-invalid {
        border-color: $color-warning;
    }


    // Elements
    &::placeholder {
        color: $color-silver;
    }
}
