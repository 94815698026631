/*
    Page
 */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    position: relative;

    min-width: 320px;
    min-height: 100%;
}

body {
    width: 100%;
    padding: 0;
    margin: 0;

    &.is-fixed {
        overflow: hidden;
    }
}
