/*
    Button Block
 */
.button-block {

    // Elements
    &-inner {
        display: flex;
        justify-content: center;
        padding-top: rem($vertical-spacing);
        padding-bottom: rem($vertical-spacing);

        @media (--from-normal-screen-w) {
            padding-top: rem($vertical-spacing-large);
            padding-bottom: rem($vertical-spacing-large);
        }

        > * {
            flex-grow: 0;
        }
    }

}
