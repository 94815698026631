/*
    Collapsible Content
 */
.collapsible-content {
    margin-bottom: rem(10);

    @media (--from-medium-screen-w) {
        margin-bottom: rem(20);
    }


    // States
    &.is-active {


        // Elements
        .collapsible-content-wrapper {
            max-height: rem(1000); // Unit big enough to animate any amount
        }

        .icon {
            transform: rotate(225deg);
        }
    }


    // Elements
    &-button {
        position: relative;

        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        margin-bottom: rem(10);

        text-align: left;
        vertical-align: top;

        background: transparent;
        border: 0;

        appearance: none;

        @media (--to-medium-screen-w) {
            outline: none; // Remove focus for button when on mobile
        }

        @media (--from-medium-screen-w) {
            pointer-events: none; // Remove ability to use this dropdown on desktop
        }
    }

    &-title {
        position: relative;

        padding-bottom: rem(10); // Weird button alignment issues with <button> so using padding-bottom
        margin-bottom: rem(0);

        line-height: rem(20);
    }

    &-wrapper {

        @media (--to-medium-screen-w) {
            max-height: rem(0);
            overflow: hidden;

            transition: max-height 0.25s ease-in-out;
        }

        @media (--from-medium-screen-w) {
            display: block;
        }
    }

    .icon {
        position: absolute;
        top: 0;
        right: 0;

        width: rem(20);
        height: rem(20);

        transition: transform 0.2s ease-in-out;

        @media (--from-medium-screen-w) {
            display: none; // Hidden ability to use this dropdown on mobile
        }
    }
}
