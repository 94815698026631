/*
    Text Content Block
*/
.text-content-block {
    border-top: 1px solid $color-light-shade-1;
    border-bottom: 1px solid $color-light-shade-1;


    // Elements
    &-main,
    &-aside {
        flex-basis: 50%;

        padding-top: rem($vertical-spacing-medium);
        padding-bottom: rem($vertical-spacing-medium);

        @media (--from-medium-screen-w) {
            padding-top: rem($vertical-spacing);
            padding-bottom: rem($vertical-spacing);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem($vertical-spacing-large);
            padding-bottom: rem($vertical-spacing-large);
        }
    }

    &-main {
        @media (--from-normal-screen-w) {
            padding-right: rem(30);

            border-right: 1px solid $color-light-shade-1;
        }
    }

    &-aside {
        @media (--from-normal-screen-w) {
            padding-left: rem(30);
        }

        @media(--to-normal-screen-w) {
            border-top: 1px solid $color-light-shade-1;
        }
    }

    &-inner {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        max-width: rem($constrain-width-large);
        margin-right: auto;
        margin-left: auto;

        @media (--from-normal-screen-w) {
            flex-direction: row;

            blockquote:first-child {
                padding-top: rem(50);
            }
        }


        // Elements
        .aside-title:before {
            content: 'aside';
            opacity: 0;

            @media (--to-normal-screen-w) {
                display: none;
            }
        }

        blockquote,
        q {
            width: inherit;
            margin: 0;

            text-align: left;

            @media (--from-medium-screen-w) {
                text-align: center;
            }

            @media (--from-normal-screen-w) {
                text-align: left;

                p {
                    font-size: rem(28);
                }
            }
        }
    }

    &-single {
        padding-top: rem($vertical-spacing-medium);
        padding-bottom: rem($vertical-spacing-medium);

        @media (--from-medium-screen-w) {
            padding-top: rem($vertical-spacing);
            padding-bottom: rem($vertical-spacing);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem($vertical-spacing-large);
            padding-bottom: rem($vertical-spacing-large);
        }
    }

    p {
        // ckeditor will apply float to aligned images
        // this makes them overflow and move elements outside the content block
        // use clearfix to prevent this
        overflow: auto;
    }

    img {
        margin-left: 0;
        margin-right: 0;
    }
}
