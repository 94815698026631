/*
    Table
*/
.table-block {
    padding-top: rem($vertical-spacing-medium);
    padding-bottom: rem($vertical-spacing-medium);

    @media (--from-medium-screen-w) {
        padding-top: rem($vertical-spacing);
        padding-bottom: rem($vertical-spacing);
    }

    @media (--from-normal-screen-w) {
        padding-top: rem($vertical-spacing-large);
        padding-bottom: rem($vertical-spacing-large);
    }

    .table-container {
        overflow-x: auto;
    }

    .custom-table {
        border-collapse: collapse;
        width: 100%;
        cursor: default;
        width: auto;

        @media (--from-medium-screen-w) {
            width: 100%;
        }
    }

    p {
        margin-bottom: rem(0);
    }

    button {
        display: block;
        margin: auto;
        margin-top: rem(10);
    }

    thead {
        background-color: $color-primary-tint-1;
        color: $color-supplementary-navy;
    }

    tr {
        background-color: #fafafa;
        transition: background-color 0.1s linear;
    }

    tr:hover {
        background-color: #ededed;
    }
    
    th,
    td {
        text-align: left;
        padding: rem(20) rem(10);
        min-width: rem(160);
        max-width: rem(320);
    }
}