/*
    Swatch
*/
$--grid-spacing: 20;

.swatch {

    // Types
    &.outline .swatch-inner {
        color: $color-dark;

        border: rem(1) solid $color-dark;


        // Elements
        .swatch-title::after {
            color: $color-dark;
        }
    }


    // Colours
    &.colour-primary .swatch-inner {
        background-color: $color-primary;


        // Elements
        .swatch-title::after {
            content: '$color-primary';
        }

        .tint-1 {
            background-color: $color-primary-tint-1;
        }

        .tint-2 {
            background-color: $color-primary-tint-2;
        }

        .shade-1 {
            background-color: $color-primary-shade-1;
        }
    }

    &.colour-secondary .swatch-inner {
        background-color: $color-secondary;


        // Elements
        .swatch-title::after {
            content: '$color-secondary';
        }

        .shade-1 {
            background-color: $color-secondary-shade-1;
        }

        .shade-2 {
            background-color: $color-secondary-shade-2;
        }
    }

    &.colour-tertiary .swatch-inner {
        color: $color-dark;
        background-color: $color-tertiary;


        // Elements
        .swatch-title::after {
            content: '$color-tertiary';
        }

        .shade-1 {
            background-color: $color-tertiary-shade-1;
        }

        .shade-2 {
            background-color: $color-tertiary-shade-2;
        }
    }

    &.colour-dark .swatch-inner {
        background-color: $color-dark;


        // Elements
        .swatch-title::after {
            content: '$color-dark';
        }

        .tint-1 {
            background-color: $color-dark-tint-1;
        }
    }

    &.colour-light .swatch-inner {
        background-color: $color-light;


        // Elements
        .swatch-title::after {
            content: '$color-light';
        }

        .shade-1 {
            background-color: $color-light-shade-1;
        }

        .shade-2 {
            background-color: $color-light-shade-2;
        }
    }

    &.colour-supplementary-1 .swatch-inner {
        background-color:  $color-supplementary-coral;

        // Elements
        .swatch-title::after {
            content: '$color-supplementary-coral';
        }

        .supplementary-1 {
            background-color: $color-supplementary-coral;
        }
    }

    &.colour-supplementary-2 .swatch-inner {
        background-color:  $color-supplementary-navy;

        // Elements
        .swatch-title::after {
            content: '$color-supplementary-navy';
        }

        .supplementary-1 {
            background-color: $color-supplementary-navy;
        }
    }


    // Elements
    &-inner {
        position: relative;

        padding-bottom: rem(40);
        overflow: hidden;

        color: $color-light;

        border-radius: rem(3);

        @media (--from-small-screen-w) {
            padding-bottom: rem(60);
        }
    }

    &-child,
    &-title {
        margin-bottom: 0;
    }

    &-child,
    &-title::after {
        font-size: rem(12);
    }

    &-child {
        padding: rem(10) rem(20);
    }

    &-title {
        padding: rem(20);
    }

    &-title,
    &-variation {

        &::after {
            display: block;

            text-transform: uppercase;

            opacity: 0.8;
        }
    }

    &-variations {
        position: absolute;
        bottom: 0;
        left: 0;

        display: flex;
        width: 100%;
        padding: 0;
        margin: 0;

        list-style: none;
    }

    &-variation {
        flex-basis: 100%;
        height: rem(20);
    }
}
