/*
    Page Header
 */
.page-header {
    height: rem(500);

    color: $color-light;

    background-color: $color-primary-shade-1;

    @media (--from-small-screen-w) {
        height: rem(600);
    }


    // Types
    &.has-no-image {
        height: 100%;
        margin-top: rem($header-height);

        background-color: $color-light;

        @media (--from-medium-screen-w) {
            margin-top: calc(rem($header-height) + rem($header-height));
        }


        // Elements
        .page-header {
            &-inner {
                position: relative;
                right: initial;
                left: initial;
            }

            &-title {
                color: $color-primary-shade-1;
            }

            &-introduction {
                color: $color-primary-shade-2;
            }
        }
    }


    // Elements
    &-wrapper {
        position: relative;

        height: 100%;
    }

    &-inner {
        position: absolute;
        right: rem($horizontal-spacing-medium);
        bottom: 0;
        left: rem($horizontal-spacing-medium);

        text-align: center;

        @media (--from-small-screen-w) {
            right: rem($horizontal-spacing);
            left: rem($horizontal-spacing);

            text-align: left;
        }

        @media (--from-medium-screen-w) {
            padding-bottom: rem(15);
        }
    }

    &-title {
        margin-bottom: rem(16);

        color: $color-light;

        @media (--from-small-screen-w) {
            margin-bottom: rem(8);
        }


        // Elements
        &::after {
            display: block;
            width: rem(40);
            height: rem(2);
            margin-top: rem(4);
            margin-right: auto;
            margin-left: auto;

            background-color: $color-primary-tint-2;

            content: '';

            @media (--from-small-screen-w) {
                display: none;
            }
        }
    }

    &-introduction {
        @media (--from-small-screen-w) {
            margin-left: rem(52);
        }


        // Elements
        &::before {
            @media (--from-small-screen-w) {
                position: absolute;
                left: 0;

                width: rem(40);
                height: rem(2);
                margin-top: rem(10);

                background-color: $color-primary-tint-2;
                content: '';
            }
        }
    }

    // Removed this style because RFA wanted to remove the background image of enquiry form for now - RFAW-3748
    // // This style only applies to /contact-us. (RFAW-3711)
    // // In case the page does not have a CB, it shall add space between these elements.
    // + .enquiry-section {
    //     margin-top: rem(25);

    //     @media (--from-small-screen-w) {
    //         margin-top: rem(50);
    //     }
    // }
}
