/*
    File Download
*/
.file-download {
    margin-top: rem($vertical-spacing);
    margin-bottom: rem($vertical-spacing);

    @media(--from-small-screen-w) {
        margin-top: rem($vertical-spacing-large);
        margin-bottom: rem($vertical-spacing-large);
    }


    // Elements
    &-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: rem(25);
        padding-bottom: rem(25);

        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: $color-light-shade-1;
    }

    &-details {
        display: flex;
        flex-direction: column;

        @media(--from-small-screen-w) {
            display: block;
        }
    }

    &-title {
        margin-bottom: rem(5);

        color: $color-primary;
        font-size: rem(16);
        font-weight: $weight-semi-bold;

        @media(--from-small-screen-w) {
            margin-bottom: rem(0);

            font-size: rem(18);
        }
    }

    &-info {
        color: $color-primary;
        font-size: rem(10);
        font-weight: $weight-semi-bold;

        @media(--from-small-screen-w) {
            margin-left: rem(15);

            font-size: rem(12);
        }

        @media(--from-normal-screen-w) {
            display: none;
        }
    }

    &-link {
        display: flex;
        flex-shrink: 0;
        padding-top: rem(4); // Aligns link with the title description
        margin-left: rem(15);

        color: $color-primary;

        @media(--to-small-screen-w) {
            align-self: flex-start;
        }


        // States
        &:hover {
            color: $color-primary;

            @media(--from-small-screen-w) {
                .file-download-link-description,
                .file-download-link-info {
                    color: $color-primary;
                }
            }

            @media(--from-normal-screen-w) {
                .file-download-link-description {
                    position: absolute;

                    opacity: 0;
                }

                .file-download-link-info {
                    position: relative;

                    opacity: 1;
                }
            }
        }


        // Elements
        &-description,
        &-info {
            display: none; // Do not display these elements on mobile

            @media(--from-small-screen-w) {
                display: block;
                padding-right: rem(20);

                color: $color-primary;
                font-size: rem(14);
                font-weight: $weight-semi-bold;
            }
        }

        &-info {
            position: absolute;

            opacity: 0;
        }

        &-description {
            text-transform: uppercase;
        }

        .icon {
            width: rem(16);
            height: rem(16);
        }
    }
}
