/*
    Icon Button
 */
.icon-button {
    padding: 0;
    margin: 0;

    color: inherit;
    font-family: inherit;

    background-color: transparent;
    border: none;


    // Types
    &.small {
        .icon {
            width: rem(20);
            height: rem(20);
        }
    }


    // Elements
    &-inner {
        // Safari doesn't support align-items center on <button> elements
        display: flex;
        align-items: center;
    }

    &-icon {
        flex-grow: 0;
    }

    &-label {
        display: none;
        flex-grow: 1;

        font-size: rem(12);
        font-weight: $weight-semi-bold;
        line-height: rem(20);
        letter-spacing: 0.1em;
        text-transform: uppercase;

        @media (--from-small-screen-w) {
            display: block;
        }


        // Order
        &:first-child {
            padding-right: rem(10);
        }

        &:last-child {
            padding-left: rem(10);
        }
    }
}
