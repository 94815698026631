/*
    Blur
 */
.svg-blur-overlay {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;

    &-image {
        width: 100%;
        height: 100%;
    }
}
