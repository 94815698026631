/*
    Fonts
*/
// National
@font-face {
    font-family: National Light;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/national/NationalWebLF-Light.woff2') format('woff2'),
        url('../../static/fonts/national/NationalWebLF-Light.woff') format('woff');
}

@font-face {
    font-family: National Regular;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/national/NationalWebLF-Regular.woff') format('woff'),
        url('../../static/fonts/national/NationalWebLF-Regular.ttf') format('truetype');
}

@font-face {
    font-family: National Semibold;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/national/NationalWebLF-Semibold.woff') format('woff'),
        url('../../static/fonts/national/NationalWebLF-Semibold.ttf') format('truetype');
}

@font-face {
    font-family: National Extrabold;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/national/NationalWebLF-Extrabold.woff') format('woff'),
        url('../../static/fonts/national/NationalWebLF-Extrabold.ttf') format('truetype');
}

@font-face {
    font-family: National Light Italic;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/national/NationalWebLF-LightItalic.woff2') format('woff2'),
        url('../../static/fonts/national/NationalWebLF-LightItalic.woff') format('woff');
}

@font-face {
    font-family: National Regular Italic;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/national/NationalWebLF-Italic.woff') format('woff'),
        url('../../static/fonts/national/NationalWebLF-Italic.ttf') format('truetype');
}

@font-face {
    font-family: National Semibold Italic;
    font-style: normal;
    font-weight: 500;
    src:  url('../../static/fonts/national/NationalWebLF-Semibold-Italic.ttf') format('truetype'), 
    url('../../static/fonts/national/NationalWebLF-Semibold-Italic.eot') format('eot');
        
}

@font-face {
    font-family: National Extrabold Italic;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/national/NationalWebLF-ExtraboldItalic.woff') format('woff'),
        url('../../static/fonts/national/NationalWebLF-ExtraboldItalic.ttf') format('truetype');
}


