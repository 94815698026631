/*
    Form
*/
.form {

    // Elements
    &-fieldset {
        display: block;
        margin-bottom: rem(10);

        font-size: rem(18);
    }

    &-field {
        padding-right: rem(12);
        padding-left: rem(12);
    }

    &-validation {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: rem(8);

        color: $color-warning;
        font-size: rem(14);
        line-height: 1;

        .icon {
            width: rem(14);
            height: rem(14);
            margin-right: rem(2);
        }
    }

    &-feedback {
        margin-bottom: rem(20);

        color: $color-light;
        text-align: center;

        border-radius: rem(4);
        box-shadow: 0 rem(2) rem(10) 0 $color-light-shade-1;

        opacity: 0;

        transform: translateY(rem(20));
        transition:
            opacity 0.15s ease-in-out 0.1s,
            transform 0.15s ease-in-out 0.1s;


        // Types
        &.success,
        &.error {
            padding: rem(15);

            opacity: 1;

            transform: translateY(0);
            transition:
                opacity 0.3s ease-in-out 0.15s,
                transform 0.25s ease-in-out 0.15s;

            @media(--from-small-screen-w) {
                padding: rem(20) rem(50);
            }
        }

        &.success {
            background-color: $color-primary;
        }

        &.error {
            background-color: $color-warning;
        }


        // Elements
        p {
            margin-bottom: 0;

            font-size: rem(16);
        }
    }

    &-button {
        margin-top: rem(30);

        @media(--from-small-screen-w) {
            margin-top: rem(20);
        }
    }
}
