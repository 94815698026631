/*
    Icon
 */
.icon {
    display: block;

    // Types
    &.reverse {
        transform: rotate(180deg);
    }
}
