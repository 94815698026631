/*
    Error page
*/
.error-page {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    margin-bottom: rem(-10);

    color: $color-primary-shade-2;

    background-color: $color-light;
    border-bottom: rem(1) solid $overlay-light-weak;

    @media(--from-medium-screen-w) {
        margin-bottom: rem(-20);
    }


    // Elements
    &-description {
        text-align: center;


        // Elements
        .breakline {
            display: none;

            @media(--from-normal-screen-w) {
                display: inline;
            }
        }

        .no-wrap {
            white-space: nowrap;
        }

        p {
            margin-bottom: rem(40);
        }
    }

    .link {
        color: $color-primary-shade-2;
        font-size: rem(16);
    }
}
